import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';

import displayReducer from '../reducers/display';
import {
    bookmarksReducer, notificationsReducer, recentOrdersReducer, remindersReducer,
    notificationCountReducer,
} from '../reducers/header';

import { FETCH_SUPPLIERS_SUCCESS } from '../actions/supplier';

import { window } from '../global';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;

const dropdownReducer = (state = initialState.dropdowns, action) => {
    return state;
};

const identityReducer = (state = initialState.identity, action) => {
    return state;
};

const suppliersReducer = (state, action) => {
    let suppliers;
    switch (action.type) {
        case FETCH_SUPPLIERS_SUCCESS:
            suppliers = action.payload.suppliers;

            return suppliers;
    }

    return state;
};

const entitiesReducer = (state = initialState.entities, action) => {
    const new_state = Object.assign({}, state, {
        suppliers: suppliersReducer(state.suppliers, action),
        bookmarks: bookmarksReducer(state.bookmarks, action),
        recent_orders: recentOrdersReducer(state.recent_orders, action),
        reminders: remindersReducer(state.reminders, action),
        notifications: notificationsReducer(state.notifications, action),
        notification_count: notificationCountReducer(
            state.notification_count, action),
    });
    return new_state;
};

export const reducers = {
    display: displayReducer,
    dropdowns: dropdownReducer,
    entities: entitiesReducer,
    identity: identityReducer,
};

export default function configureSuppliersStore(initialState) {
    const store = createStore(
        combineReducers(reducers),
        initialState,
        compose(
            applyMiddleware(thunk, api),
            window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
                : f => f
        )
    );

    return store;
}
