import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { reducers } from '../store/configureSuppliersStore';
import withReducers from '../store/withReducers';
import { getPopups } from '../selectors';
import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import SuppliersView from '../components/SuppliersView';
import SuppliersView2 from '../components/SuppliersView2';
import ErrorBoundary from '../components/ErrorBoundary';
import { createAddSupplierPopup } from '../actions/popup';

const SuppliersApp = ({version = 'v1', popups, onCreateAddSupplierPopup }) => {
  const headerContainerRef = useRef(null);
  const [topPadding, setTopPadding] = useState(window.innerWidth <= 1023 ? 145 : 120);
  const SuppliersViewComponent = version === 'v2' ? SuppliersView2 : SuppliersView;

  useEffect(() => {
    if (headerContainerRef.current) {
      setTopPadding(headerContainerRef.current.offsetHeight);
    }
  }, []);

  return (
    <div className="supplier-listing">
      <ErrorBoundary>
        <Header headerContainerRef={headerContainerRef}/>
        <div className="resku"><MainSection popups={popups} /></div>
        <SuppliersViewComponent style={{ paddingTop: topPadding }} onCreateAddSupplierPopup={onCreateAddSupplierPopup} />
        <Overlay popups={popups} />
      </ErrorBoundary>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
    identity: state.identity,
    popups: getPopups(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onCreateAddSupplierPopup: (redirect) => {
            dispatch(createAddSupplierPopup(redirect));
        },
    };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(SuppliersApp), reducers);
